import { ComponentType, useState } from 'react';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HandshakeIcon from '@mui/icons-material/HandshakeOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import WorkIcon from '@mui/icons-material/WorkOutlineOutlined';
import { IconButton } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import { routes } from 'app/Router';
import useCheckSportBudgetPermission from 'shared/hooks/useCheckSportBudgetPermission';

const useRouteMatch = (patterns: readonly string[]) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(`${routes.sportBudget}/${pattern}/*`, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};

const SportBudgetSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isDevelopmentGroup } = useCheckSportBudgetPermission();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const routeMatch = useRouteMatch([
    routes.sportBudgetPlannedDeals,
    routes.sportBudgetImpactReports,
    routes.sportBudgetActualDeals,
    routes.sportBudgetExternalPayments,
    routes.sportBudgetLicensings,
    routes.sportBudgetEmploymentContracts,
    routes.sportBudgetPersonalPayments,
  ]);
  const currentLink = routeMatch?.pathnameBase;

  const renderLink = (path: string, label: string, Icon: ComponentType) => {
    const link = `${routes.sportBudget}/${path}`;
    const isActive = currentLink === link;

    return (
      <SidebarLink to={link} title={!isSidebarOpen ? label : undefined} $active={isActive}>
        <Icon />
        {label}
      </SidebarLink>
    );
  };

  return (
    <Sidebar $isOpen={isSidebarOpen}>
      {renderLink(routes.sportBudgetPlannedDeals, 'Запланированные сделки', HandshakeIcon)}
      {renderLink(routes.sportBudgetImpactReports, 'Моделирование', QueryStatsOutlinedIcon)}
      {isDevelopmentGroup && (
        <>
          <Separator />
          {renderLink(
            routes.sportBudgetActualDeals,
            'Фактические сделки',
            RequestQuoteOutlinedIcon,
          )}
          {renderLink(
            routes.sportBudgetExternalPayments,
            'Внешние платежи',
            AccountBalanceOutlinedIcon,
          )}
          {renderLink(routes.sportBudgetLicensings, 'Лицензирование', TableChartOutlinedIcon)}
          <Separator />
          {renderLink(routes.sportBudgetEmploymentContracts, 'Трудовые контракты', WorkIcon)}
          {renderLink(
            routes.sportBudgetPersonalPayments,
            'Личные платежи',
            AccountBalanceWalletIcon,
          )}
        </>
      )}
      <TogglerWrapper>
        <IconButton onClick={toggleSidebar}>
          {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </TogglerWrapper>
    </Sidebar>
  );
};

export default SportBudgetSidebar;

const Sidebar = styled.div<{ $isOpen: boolean }>`
  flex: 0 0 auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  width: ${({ $isOpen }) => ($isOpen ? '255px' : 'calc(22px + var(--space-sm) * 2)')};
  transition: width var(--transition);

  button {
    align-self: flex-start;
    margin-left: 4px;

    svg {
      height: 22px;
      width: 22px;
    }
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutral-border-light);
`;

const SidebarLink = styled(Link)<{ $active: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-xs) var(--space-sm);
  padding-right: 0;
  border-radius: var(--radii-md);
  color: var(--text-subtle);
  user-select: none;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: var(--text);
  }

  svg {
    height: 22px;
    width: 22px;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: var(--primary);
      background-color: white;
      box-shadow: var(--shadow-sm);
      pointer-events: none;
    `}
`;

const TogglerWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
