import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import SportBudgetSidebar from './ui/Sidebar';
import Header from 'shared/components/header';

const SportBudgetLayout = () => (
  <Main>
    <Header />
    <Container>
      <SportBudgetSidebar />
      <Content>
        <Suspense fallback={<CircularProgress sx={{ margin: '2rem auto' }} />}>
          <Outlet />
        </Suspense>
      </Content>
    </Container>
  </Main>
);

export default SportBudgetLayout;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
`;

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  gap: var(--space-md);
  width: 100%;
  margin: 0 auto;
  padding: var(--space-md);
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--space-xl);
  background-color: var(--neutral-bg);
  border: 1px solid var(--slate-4);
  border-radius: var(--radii-lg);
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: var(--space-md);
    border: none;
    border-radius: 0;
  }
`;
