import useUserStore from '../../entities/User.model';

const useCheckSportBudgetPermission = () => {
  const { record, currentOrgUser } = useUserStore();

  // Lesha B., Evgeniy, Petr, Lesha P., Bogdan, Vitaliy, accountant, Nikita P.
  const userIds = ['1', '2', '45', '3', '125', '379', '183', '522'];
  const isDevelopmentGroup = userIds.includes(record.user.id);
  const isTopManager = ['110', '113'].includes(currentOrgUser?.orgRole.id);
  const isSportBudgetPageAllowed = isDevelopmentGroup || isTopManager;

  return {
    isSportBudgetPageAllowed,
    isDevelopmentGroup,
  };
};

export default useCheckSportBudgetPermission;
