import styled from '@emotion/styled/macro';

import useUserStore from 'entities/User.model';
import useNotificationsStore from 'features/Notifications/Notifications.model';
import { OrgCapabilityEnum } from 'shared/api/user';
import TelegramLoginButton from 'shared/components/TelegramLoginButton';

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const { identified, currentOrgUser, record, checkOrgCapability } = useUserStore();
  const notification = useNotificationsStore();

  const handleLinkTelegram = () => {
    window.location.reload();
  };

  const handleLinkTelegramError = (error?: { code: string; message: string }) => {
    if (error?.code === 'telegram_already_used') {
      notification.send({ title: 'Этот Telegram-аккаунт уже используется', type: 'error' });
    } else if (error?.code === 'telegram_not_found') {
      notification.send({ title: 'Не удалось привязать аккаунт к Telegram', type: 'error' });
    }
  };

  if (!identified || !currentOrgUser.organization.capabilities) return null;

  if (!checkOrgCapability(OrgCapabilityEnum.Portal)) {
    return (
      <div style={{ maxWidth: 420, textAlign: 'center' }}>
        Извините, у вас нет доступа к порталу. Пожалуйста, обратитесь к администратору за
        подробностями.
      </div>
    );
  }

  if (currentOrgUser?.orgRole.telegramRequired && !record.user.hasTelegram) {
    return (
      <TelegramMessage>
        Для работы с порталом необходимо привязать Telegram к вашему аккаунту
        <TelegramLoginButton onLogin={handleLinkTelegram} onError={handleLinkTelegramError} />
      </TelegramMessage>
    );
  }

  return children;
};

export default RequireAuth;

const TelegramMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-md);
  text-align: center;
`;
