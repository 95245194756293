import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import useUserStore from 'entities/User.model';
import playerService, { PlayerFragment, PlayerUpdateInput } from 'shared/api/player';
import scoutingService, { ScoutingTaskShortFragment } from 'shared/api/scouting';

type PlayerStore = {
  playerData: PlayerFragment;
  playerScoutingTasks: ScoutingTaskShortFragment[];
};

type PlayerActions = {
  getPlayerData: (id: string) => Promise<PlayerFragment>;
  updatePlayer: (input: PlayerUpdateInput) => Promise<PlayerFragment>;
};

export const usePlayerStore = create(
  immer<PlayerStore & PlayerActions>((set) => ({
    playerData: null,
    playerScoutingTasks: [],

    getPlayerData: async (id: string) => {
      const playerData = await playerService.getPlayer(id);
      const scoutingTasks = await scoutingService.queryScoutingTasks({
        orgId: useUserStore.getState().currentOrgUser.organization.id,
        playerId: id,
      });
      set({ playerData, playerScoutingTasks: scoutingTasks.nodes });
      return playerData;
    },

    updatePlayer: async (input: PlayerUpdateInput) => {
      const playerData = await playerService.updatePlayer(input);
      set({ playerData });
      return playerData;
    },
  })),
);
