import { graphql } from 'gql';

import { client } from './client';
import * as GQLTypes from 'gql/graphql';

export {
  ScoutingProfileActionEnum,
  ScoutingReportFieldTypeEnum,
  ScoutingReportGradeEnum,
  PlayerGradeEnum,
} from 'gql/graphql';

export type {
  ScoutingAssignmentFragment,
  ScoutingTaskShortFragment,
  ScoutingTaskCommonFragment,
  ScoutingProfileShortFragment,
  ScoutingProfileCommonFragment,
  ScoutingProfileChangeFragment,
  ScoutingStatusFragment,
  PlayerScoutingData,
  PlayerReportPreviewFragment,
  ScoutingReportGroup,
  ScoutingReportFragment,
  ScoutingReportFormFragment,
  ScoutingReportFieldConditionFragment,
  ScoutingReportFieldShortFragment,
  VideoShortFragment,
  ScoutingTaskCreateInput,
  ScoutingTaskUpdateInput,
} from 'gql/graphql';

export type ScoutingProfileChangeShortFragment = {
  id: string;
  action?: GQLTypes.ScoutingProfileActionEnum;
  user?: {
    id: string;
    name: string;
  };
};

export type ScoutingProfileReportForm = {
  groups: {
    name: string;
    fields: GQLTypes.ScoutingReportFieldShortFragment[];
  }[];
};

// FRAGMENTS //////////////////////////////////////////////////////////////////

graphql(`
  fragment ScoutingTaskShort on ScoutingTask {
    id
    name
    totalProfiles: profilesCount(statuses: [], archived: false)
    completedAt
    longlist
    usersWithAccess {
      id
      name
    }
  }
`);

graphql(`
  fragment ScoutingStatus on ScoutingTaskPresetStatus {
    key
    name
    readRoles(orgId: $orgId) {
      id
    }
    changeStatusRules {
      to
      roles(orgId: $orgId) {
        id
      }
    }
    checks {
      id
      name
    }
  }
`);

graphql(`
  fragment ScoutingTaskCommon on ScoutingTask {
    id
    name
    description
    role {
      id
      name
    }
    scoutingTaskPreset {
      statuses {
        ...ScoutingStatus
      }
    }
    creator {
      id
      name
    }
    shortlistDeadlineAt
    signingDeadlineAt
    completedAt
    longlist
    transferFee
    commission
    salary
    expensesFor6Months
    expensesFor12Months
    usersWithAccess {
      id
      name
    }
  }
`);

graphql(`
  fragment ScoutingProfileShort on ScoutingProfile {
    id
    status
    lastStatusChangedAt
    createdAt
    archived
    player {
      ...PlayerShort
    }
    checks {
      id
      name
      passed
    }
    rating {
      grade
      potentialGrade
    }
    priority
    actions: changes(isAction: true) {
      id
      action
      user {
        id
        name
      }
    }
    reports {
      id
      configId
      scout {
        id
        name
      }
      nextSteps
      grade
      change {
        createdAt
      }
    }
  }
`);

graphql(`
  fragment ScoutingProfileCommon on ScoutingProfile {
    id
    status
    lastStatusChangedAt
    createdAt
    createdBy {
      id
      name
    }
    archived
    player {
      ...Player
    }
    checks {
      id
      name
      passed
    }
    rating {
      grade
      potentialGrade
    }
    priority
    agent
    transferConditions
    changes {
      ...ScoutingProfileChange
    }
    task {
      id
      name
      longlist
    }
    anotherTasks {
      id
      name
      profiles {
        id
        player {
          id
        }
      }
    }
  }
`);

graphql(`
  fragment ScoutingProfileChange on ScoutingProfileChange {
    action
    comments
    createdAt
    id
    newStatus
    previousStatus
    priority
    agent
    transferConditions
    archived
    attachment {
      byteSize
      contentType
      id
      name
      presignedUrl
      status
    }
    report {
      id
      configId
      summary
      grade
      nextSteps
      match {
        name
      }
    }
    video {
      ...VideoShort
    }
    user {
      id
      name
    }
    scoutingProfile {
      checks {
        id
        name
        passed
      }
    }
    scoutingAssignment {
      id
      checkId
      configId
      user {
        id
        name
      }
      deadline
      doneAt
    }
  }
`);

graphql(`
  fragment ScoutingReport on ScoutingReport {
    reportForm {
      groups {
        name
        fields {
          ...ScoutingReportFieldShort
        }
      }
    }
    position {
      id
    }
    configId
    summary
    grade
    nextSteps
    scout {
      id
      name
    }
    match {
      ...MatchShort
    }
  }
`);

graphql(`
  fragment ScoutingReportFieldShort on ScoutingReportField {
    key
    label
    types
    booleanValue
    floatValue
    intValue
    ratingValue
    textValue
    videoValue
    gradeValue
    potentialValue
    dateValue
    conditionalValue
    conditions {
      ...ScoutingReportFieldCondition
    }
    video {
      ...VideoShort
    }
    unit
  }
`);

graphql(`
  fragment ScoutingReportFieldCondition on ScoutingReportFieldCondition {
    key
    label
    fields {
      key
      label
      unit
      types
      floatValue
      textValue
      dateValue
    }
  }
`);

graphql(`
  fragment VideoShort on Video {
    id
    name
    transcodedVideoUrl: transcodedMediaUrl(asAttachment: false, clck: false)
    originalVideoUrl: originalMediaUrl(asAttachment: false, clck: false)
  }
`);

graphql(`
  fragment PlayerReportPreview on ScoutingReport {
    id
    configId
    summary
    scout {
      id
      name
    }
    match {
      name
    }
    change {
      createdAt
      scoutingProfile {
        id
        player {
          id
          name
        }
        rating {
          grade
          potentialGrade
        }
        task {
          id
          name
        }
      }
    }
  }
`);

graphql(`
  fragment ScoutingReportForm on ScoutingReportForm {
    id
    name
    availableForRoles
  }
`);

graphql(`
  fragment ScoutingAssignment on ScoutingAssignment {
    id
    createdAt
    deadline
    doneAt
    configId
    user {
      id
      name
    }
    scoutingProfile {
      id
      player {
        id
        name
      }
      task {
        id
        name
      }
    }
  }
`);

// QUERIES ////////////////////////////////////////////////////////////////////

const SCOUTING_TASKS_QUERY = graphql(`
  query ScoutingTasks($orgId: ID!, $playerId: ID) {
    scoutingTasks(orgId: $orgId, playerId: $playerId) {
      ... on ScoutingTaskConnection {
        nodes {
          ...ScoutingTaskShort
        }
      }
      ... on ScoutingTasksQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_TASK_QUERY = graphql(`
  query ScoutingTask($id: ID!, $orgId: ID!) {
    scoutingTask(id: $id) {
      ...ScoutingTaskCommon
      ... on ScoutingTaskQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_PROFILES_QUERY = graphql(`
  query ScoutingProfiles(
    $taskId: ID!
    $statuses: [String!]
    $archived: Boolean
    $query: String
    $after: String
    $first: Int
  ) {
    scoutingProfiles(
      taskId: $taskId
      statuses: $statuses
      archived: $archived
      query: $query
      after: $after
      first: $first
    ) {
      ... on ScoutingProfileConnection {
        nodes {
          ...ScoutingProfileShort
        }

        pageInfo {
          endCursor
          hasNextPage
        }
      }
      ... on ScoutingProfilesQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_PROFILE_QUERY = graphql(`
  query ScoutingProfile($id: ID!) {
    scoutingProfile(id: $id) {
      ...ScoutingProfileCommon
      ... on ScoutingProfileQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_PROFILE_DATA_QUERY = graphql(`
  query PlayerScoutingData($playerId: ID!) {
    playerScoutingData(playerId: $playerId) {
      ... on PlayerScoutingData {
        assists
        contractExpirationDate
        goals
        id
        lastYearFullMatches
        lastYearMatches
        lastYearMinutes
        marketValue
        missedMatches
        name
        nationalTeam
        nationalTeamCaps
        position
      }
      ... on PlayerScoutingDataQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_REPORT_FORM_QUERY = graphql(`
  query ScoutingProfileReportForm($profileId: ID!, $configId: String) {
    scoutingProfileReportForm(profileId: $profileId, configId: $configId) {
      ... on ScoutingProfileReportForm {
        groups {
          name
          fields {
            ...ScoutingReportFieldShort
          }
        }
      }
      ... on ScoutingProfileReportFormQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_REPORT_QUERY = graphql(`
  query ScoutingReport($scoutingReportId: ID!) {
    scoutingReport(id: $scoutingReportId) {
      ...ScoutingReport

      ... on ScoutingReportQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_REPORTS_QUERY = graphql(`
  query ScoutingReports(
    $orgId: ID!
    $playerId: ID
    $common: Boolean
    $taskIds: [ID!]
    $configIds: [ID!]
    $scoutIds: [ID!]
    $first: Int
  ) {
    playerScoutingReports(
      orgId: $orgId
      playerId: $playerId
      common: $common
      taskIds: $taskIds
      configIds: $configIds
      scoutIds: $scoutIds
      first: $first
    ) {
      ... on ScoutingReportConnection {
        nodes {
          ...PlayerReportPreview
        }
      }

      ... on PlayerScoutingReportsQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_REPORT_FORMS_QUERY = graphql(`
  query ScoutReportForms {
    scoutingReportForms {
      ... on ScoutingReportFormConnection {
        nodes {
          ...ScoutingReportForm
        }
      }

      ... on ScoutingReportFormsQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_PLAYER_ROLES = graphql(`
  query ScoutingPlayerRoles($orgId: ID!) {
    roles(orgId: $orgId) {
      ... on RoleConnection {
        nodes {
          id
          name
        }
      }

      ... on RolesQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_ASSIGNMENTS_QUERY = graphql(`
  query ScoutingAssignments($orgId: ID!, $userId: ID) {
    scoutingAssignments(orgId: $orgId, userId: $userId) {
      ... on ScoutingAssignmentConnection {
        nodes {
          ...ScoutingAssignment
        }
      }

      ... on ScoutingAssignmentsQueryDomainError {
        code
        message
      }
    }
  }
`);

const SCOUTING_ASSIGNMENT_USERS_QUERY = graphql(`
  query ScoutingAssignmentUsers($profileId: ID!) {
    scoutingAssignmentUsers(profileId: $profileId) {
      ... on ScoutingAssignmentUsers {
        users {
          id
          name
        }
      }
      ... on ScoutingAssignmentUsersQueryDomainError {
        code
        message
      }
    }
  }
`);

// MUTATIONS //////////////////////////////////////////////////////////////////

const CREATE_SCOUTING_TASK_MUTATION = graphql(`
  mutation CreateTask($input: ScoutingTaskCreateInput!) {
    scoutingTask {
      create(input: $input) {
        scoutingTask {
          id
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_SCOUTING_TASK_MUTATION = graphql(`
  mutation UpdateTask($input: ScoutingTaskUpdateInput!, $orgId: ID!) {
    scoutingTask {
      update(input: $input) {
        scoutingTask {
          ...ScoutingTaskCommon
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const CREATE_SCOUTING_PROFILE_WITH_TM_MUTATION = graphql(`
  mutation CreateWithTm($input: ScoutingProfileCreateWithTmInput!) {
    scoutingProfile {
      createWithTm(input: $input) {
        profile {
          ...ScoutingProfileShort
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const CREATE_SCOUTING_PROFILE_MUTATION = graphql(`
  mutation Create($input: ScoutingProfileCreateInput!) {
    scoutingProfile {
      create(input: $input) {
        profile {
          ...ScoutingProfileShort
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const FIND_OR_CREATE_SCOUTING_PROFILE_MUTATION = graphql(`
  mutation FindOrCreate($input: ScoutingProfileFindOrCreateInput!) {
    scoutingProfile {
      findOrCreate(input: $input) {
        scoutingProfile {
          id
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_SCOUTING_PROFILE_MUTATION = graphql(`
  mutation UpdateScoutingProfile($input: ScoutingProfileChangeInput!) {
    scoutingProfile {
      change(input: $input) {
        scoutingProfileChange {
          ...ScoutingProfileChange
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const MOVE_SCOUTING_PROFILE_MUTATION = graphql(`
  mutation Move($input: ScoutingProfileMoveInput!) {
    scoutingProfile {
      move(input: $input) {
        result

        error {
          code
          message
        }
      }
    }
  }
`);

const CREATE_SCOUTING_REPORT_MUTATION = graphql(`
  mutation CreateReport($input: ScoutingReportCreateInput!) {
    scoutingReport {
      create(input: $input) {
        scoutingReport {
          change {
            ...ScoutingProfileChange
          }
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_SCOUTING_REPORT_MUTATION = graphql(`
  mutation UpdateReport($input: ScoutingReportUpdateInput!) {
    scoutingReport {
      update(input: $input) {
        scoutingReport {
          ...ScoutingReport
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const CREATE_SCOUTING_ASSIGNMENT_MUTATION = graphql(`
  mutation CreateScoutingAssignment($input: ScoutingAssignmentCreateInput!) {
    scoutingAssignment {
      create(input: $input) {
        changes {
          ...ScoutingProfileChange
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const DELETE_SCOUTING_ASSIGNMENT_MUTATION = graphql(`
  mutation DeleteScoutingAssignment($input: ScoutingAssignmentDeleteInput!) {
    scoutingAssignment {
      delete(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`);

const queries = {
  async queryScoutingTasks(variables: GQLTypes.ScoutingTasksQueryVariables) {
    const result = await client.query({
      query: SCOUTING_TASKS_QUERY,
      variables,
    });

    if (result.data.scoutingTasks.__typename === 'ScoutingTasksQueryDomainError') {
      return Promise.reject(result.data.scoutingTasks);
    }

    return result.data.scoutingTasks;
  },

  async queryScoutingTask(id: string, orgId: string) {
    const result = await client.query({
      query: SCOUTING_TASK_QUERY,
      variables: { id, orgId },
    });

    if (result.data.scoutingTask.__typename === 'ScoutingTaskQueryDomainError') {
      return Promise.reject(result.data.scoutingTask);
    }

    return result.data.scoutingTask;
  },

  async queryScoutingProfiles(variables: GQLTypes.QueryScoutingProfilesArgs) {
    const result = await client.query({
      query: SCOUTING_PROFILES_QUERY,
      variables,
    });

    if (result.data.scoutingProfiles.__typename === 'ScoutingProfilesQueryDomainError') {
      return Promise.reject(result.data.scoutingProfiles);
    }

    return result.data.scoutingProfiles;
  },

  async queryScoutingProfile(id: string) {
    const result = await client.query({
      query: SCOUTING_PROFILE_QUERY,
      variables: { id },
    });

    if (result.data.scoutingProfile.__typename === 'ScoutingProfileQueryDomainError') {
      return Promise.reject(result.data.scoutingProfile);
    }

    return result.data.scoutingProfile;
  },

  async queryPlayerData(playerId: string) {
    const result = await client.query({
      query: SCOUTING_PROFILE_DATA_QUERY,
      variables: { playerId },
    });

    if (result.data.playerScoutingData.__typename === 'PlayerScoutingDataQueryDomainError') {
      return Promise.reject(result.data.playerScoutingData);
    }

    return result.data.playerScoutingData;
  },

  async queryProfileReportForm(profileId: string, configId?: string) {
    const result = await client.query({
      query: SCOUTING_REPORT_FORM_QUERY,
      variables: {
        profileId,
        configId: configId || '38_params',
      },
    });

    if (
      result.data.scoutingProfileReportForm.__typename ===
      'ScoutingProfileReportFormQueryDomainError'
    ) {
      return Promise.reject(result.data.scoutingProfileReportForm);
    }

    return result.data.scoutingProfileReportForm;
  },

  async queryReport(scoutingReportId: string) {
    const result = await client.query({
      query: SCOUTING_REPORT_QUERY,
      variables: { scoutingReportId },
    });

    if (result.data.scoutingReport.__typename === 'ScoutingReportQueryDomainError') {
      return Promise.reject(result.data.scoutingReport);
    }

    return result.data.scoutingReport;
  },

  async queryScoutingReports(variables: GQLTypes.QueryPlayerScoutingReportsArgs) {
    const result = await client.query({
      query: SCOUTING_REPORTS_QUERY,
      variables,
    });

    if (result.data.playerScoutingReports.__typename === 'PlayerScoutingReportsQueryDomainError') {
      return Promise.reject(result.data.playerScoutingReports);
    }

    return result.data.playerScoutingReports;
  },

  async queryScoutingReportForms() {
    const result = await client.query({
      query: SCOUTING_REPORT_FORMS_QUERY,
    });

    if (result.data.scoutingReportForms.__typename === 'ScoutingReportFormsQueryDomainError') {
      return Promise.reject(result.data.scoutingReportForms);
    }

    return result.data.scoutingReportForms.nodes;
  },

  async queryScoutingPlayerRoles(orgId: string) {
    const result = await client.query({
      query: SCOUTING_PLAYER_ROLES,
      variables: { orgId },
    });

    if (result.data.roles.__typename === 'RolesQueryDomainError') {
      return Promise.reject(result.data.roles);
    }

    return result.data.roles.nodes;
  },

  async queryScoutingAssignments(variables: GQLTypes.QueryScoutingAssignmentsArgs) {
    const result = await client.query({
      query: SCOUTING_ASSIGNMENTS_QUERY,
      variables,
    });

    if (result.data.scoutingAssignments.__typename === 'ScoutingAssignmentsQueryDomainError') {
      return Promise.reject(result.data.scoutingAssignments);
    }

    return result.data.scoutingAssignments.nodes;
  },

  async queryScoutingAssignmentUsers(profileId: string) {
    const result = await client.query({
      query: SCOUTING_ASSIGNMENT_USERS_QUERY,
      variables: { profileId },
    });

    if (
      result.data.scoutingAssignmentUsers.__typename === 'ScoutingAssignmentUsersQueryDomainError'
    ) {
      return Promise.reject(result.data.scoutingAssignmentUsers);
    }

    return result.data.scoutingAssignmentUsers.users;
  },
};

const mutations = {
  async createScoutingTask(input: GQLTypes.ScoutingTaskCreateInput) {
    const result = await client.mutate({
      mutation: CREATE_SCOUTING_TASK_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingTask?.create?.error) {
      return Promise.reject(result.data.scoutingTask.create.error.message);
    }

    return result.data.scoutingTask.create.scoutingTask.id;
  },

  async updateScoutingTask(input: GQLTypes.ScoutingTaskUpdateInput, orgId: string) {
    const result = await client.mutate({
      mutation: UPDATE_SCOUTING_TASK_MUTATION,
      variables: {
        input,
        orgId,
      },
    });

    if (result.data?.scoutingTask?.update?.error) {
      return Promise.reject(result.data.scoutingTask.update.error.message);
    }

    return result.data.scoutingTask.update.scoutingTask;
  },

  async createScoutingProfileWithTm(input: GQLTypes.ScoutingProfileCreateWithTmInput) {
    const result = await client.mutate({
      mutation: CREATE_SCOUTING_PROFILE_WITH_TM_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingProfile?.createWithTm?.error) {
      return Promise.reject(result.data.scoutingProfile.createWithTm.error.message);
    }

    return result.data.scoutingProfile.createWithTm.profile;
  },

  async createScoutingProfile(input: GQLTypes.ScoutingProfileCreateInput) {
    const result = await client.mutate({
      mutation: CREATE_SCOUTING_PROFILE_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingProfile?.create?.error) {
      return Promise.reject(result.data.scoutingProfile.create.error.message);
    }

    return result.data.scoutingProfile.create.profile;
  },

  async findOrCreateScoutingProfile(input: GQLTypes.ScoutingProfileFindOrCreateInput) {
    const result = await client.mutate({
      mutation: FIND_OR_CREATE_SCOUTING_PROFILE_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingProfile?.findOrCreate?.error) {
      return Promise.reject(result.data.scoutingProfile.findOrCreate.error.message);
    }

    return result.data.scoutingProfile.findOrCreate.scoutingProfile.id;
  },

  async updateScoutingProfile(input: GQLTypes.ScoutingProfileChangeInput) {
    const result = await client.mutate({
      mutation: UPDATE_SCOUTING_PROFILE_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingProfile?.change?.error) {
      return Promise.reject(result.data.scoutingProfile.change.error.message);
    }

    return result.data.scoutingProfile.change.scoutingProfileChange;
  },

  async moveScoutingProfile(input: GQLTypes.ScoutingProfileMoveInput) {
    const result = await client.mutate({
      mutation: MOVE_SCOUTING_PROFILE_MUTATION,
      variables: {
        input,
      },
    });

    if (result.data?.scoutingProfile?.move?.error) {
      return Promise.reject(result.data.scoutingProfile.move.error.message);
    }

    return result.data.scoutingProfile.move.result;
  },

  async createScoutingReport(input: GQLTypes.ScoutingReportCreateInput) {
    const result = await client.mutate({
      mutation: CREATE_SCOUTING_REPORT_MUTATION,
      variables: {
        input,
      },
    });

    if (result.errors) {
      return Promise.reject(result.errors);
    }

    if (result.data?.scoutingReport?.create?.error) {
      return Promise.reject(result.data.scoutingReport.create.error.message);
    }

    return result.data.scoutingReport.create.scoutingReport.change;
  },

  async updateScoutingReport(input: GQLTypes.ScoutingReportUpdateInput) {
    const result = await client.mutate({
      mutation: UPDATE_SCOUTING_REPORT_MUTATION,
      variables: {
        input,
      },
    });

    if (result.errors) {
      return Promise.reject(result.errors);
    }

    if (result.data?.scoutingReport?.update?.error) {
      return Promise.reject(result.data.scoutingReport.update.error.message);
    }

    return result.data.scoutingReport.update.scoutingReport;
  },

  async createScoutingAssignment(input: GQLTypes.ScoutingAssignmentCreateInput) {
    const result = await client.mutate({
      mutation: CREATE_SCOUTING_ASSIGNMENT_MUTATION,
      variables: {
        input,
      },
    });

    if (result.errors) {
      return Promise.reject(result.errors);
    }

    if (result.data?.scoutingAssignment?.create?.error) {
      return Promise.reject(result.data.scoutingAssignment.create.error.message);
    }

    return result.data.scoutingAssignment.create.changes;
  },

  async deleteScoutingAssignment(input: GQLTypes.ScoutingAssignmentDeleteInput) {
    const result = await client.mutate({
      mutation: DELETE_SCOUTING_ASSIGNMENT_MUTATION,
      variables: { input },
    });

    if (result.data.scoutingAssignment.delete.error) {
      return Promise.reject(result.data.scoutingAssignment.delete.error);
    }

    return result.data.scoutingAssignment;
  },
};

const scoutingService = {
  ...queries,
  ...mutations,
};

export default scoutingService;
