import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import useUserStore from 'entities/User.model';

export type AppStore = {
  init: () => Promise<void>;
};

export const useAppStore = create(
  immer<AppStore, []>(() => ({
    async init() {
      try {
        await useUserStore.getState().fetchUser();
      } catch (exx) {}
    },
  })),
);
