import { useState } from 'react';
import debounce from 'lodash/debounce';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import { routes } from 'app/Router';
import useUserStore from 'entities/User.model';
import PlayerForm from 'features/PlayerForm';
import playerService, { PlayerShortFragment } from 'shared/api/player';
import { OrgRolePermissionActionEnum } from 'shared/api/user';

const PlayerSearch = () => {
  const navigate = useNavigate();
  const { checkPermission, currentOrgUser } = useUserStore();
  const [players, setPlayers] = useState<PlayerShortFragment[]>([]);
  const [openResults, setOpenResults] = useState(false);
  const [emptySearch, setEmptySearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createPlayerDialogOpen, setCreatePlayerDialogOpen] = useState(false);

  const handleSwitchOpenResults = () => {
    setTimeout(
      () => {
        setOpenResults(!openResults);
      },
      openResults ? 300 : 0,
    );
  };

  const handleSearch = debounce(async (value: string) => {
    if (!value || !value.length) {
      setPlayers([]);
      setEmptySearch(true);
      return;
    }

    setEmptySearch(false);
    setLoading(true);

    const result = await playerService.searchPlayers({
      query: value,
      first: 20,
      after: null,
      organizationId: currentOrgUser.organization.id,
    });

    setPlayers(result.nodes);
    setLoading(false);
  }, 500);

  const goToPlayer = (player: PlayerShortFragment) => {
    navigate(generatePath(routes.player, { playerId: player.id }));
  };

  // TODO change to ManagePlayers when it's ready on backend
  const canCreatePlayer = checkPermission(OrgRolePermissionActionEnum.ManageMatches);

  return (
    <>
      <Wrapper>
        <SearchInput
          type="text"
          placeholder="Поиск игроков"
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={handleSwitchOpenResults}
          onBlur={handleSwitchOpenResults}
        />

        {!emptySearch && openResults && (
          <SearchResults>
            {loading && !players.length && <StateText>Поиск...</StateText>}

            <ScrollableWrapper>
              {players.map((player) => (
                <SearchResult key={player.id} onClick={() => goToPlayer(player)}>
                  {player.name}
                  {player.currentTeam && <span>{player.currentTeam.name}</span>}
                </SearchResult>
              ))}
            </ScrollableWrapper>

            {!loading && players.length > 0 && canCreatePlayer && (
              <NewPlayerCTA>
                <StateText>
                  Нет игрока, которого вы ищете? Попробуйте поискать на другом языке или{' '}
                  <CreatePlayerTrigger onClick={() => setCreatePlayerDialogOpen(true)}>
                    Создайте игрока
                  </CreatePlayerTrigger>
                  .
                </StateText>
              </NewPlayerCTA>
            )}

            {!loading && !players.length && (
              <StateText>
                Игрок не найден. Попробуйте поискать на другом языке{' '}
                {canCreatePlayer && (
                  <>
                    или{' '}
                    <CreatePlayerTrigger onClick={() => setCreatePlayerDialogOpen(true)}>
                      создайте игрока
                    </CreatePlayerTrigger>
                  </>
                )}
                .
              </StateText>
            )}
          </SearchResults>
        )}
      </Wrapper>

      <PlayerForm
        open={createPlayerDialogOpen}
        onClose={() => setCreatePlayerDialogOpen(false)}
        onSubmit={goToPlayer}
      />
    </>
  );
};

export default PlayerSearch;

const Wrapper = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  width: 250px;
  height: 30px;
  padding: 0 var(--space-md);
  background-color: var(--slate-7);
  border: none;
  border-radius: var(--radii-sm);
  font-family: var(--jost);
  font-size: var(--fz-sm);
  color: var(--text);
  transition: background-color var(--transition);
  outline: none;

  &:focus {
    background-color: var(--slate-8);
  }

  @media (max-width: 768px) {
    width: 225px;
  }

  @media (max-width: 400px) {
    width: 150px;
  }
`;

const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
  margin-top: var(--space-xxs);
  padding: var(--space-xs);
  background-color: var(--slate-5);
  border: none;
  border-radius: var(--radii-sm);
  z-index: 100;
`;

const ScrollableWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const SearchResult = styled.a`
  padding: var(--space-xxs) var(--space-sm);
  font-size: var(--fz-sm);
  color: var(--text);
  border-radius: var(--radii-sm);
  transition: background-color var(--transition);
  text-decoration: none;
  cursor: pointer;

  span {
    display: block;
    font-size: var(--fz-xs);
    color: var(--text-subtle);
  }

  &:hover {
    background-color: var(--slate-7);
    text-decoration: none;
  }
`;

const NewPlayerCTA = styled.div`
  flex: 0 0 auto;
  margin-top: var(--space-xxs);
  border-top: 1px solid var(--neutral-border);
`;

const StateText = styled.div`
  padding: var(--space-xxs) var(--space-sm);
  font-size: var(--fz-sm);
  color: var(--text-subtle);
`;

const CreatePlayerTrigger = styled.span`
  color: var(--primary);
  text-decoration: dashed underline;
  cursor: pointer;

  &:hover {
    color: var(--primary-hover);
    text-decoration: none;
  }
`;
