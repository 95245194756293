import { graphql } from 'gql';

import { client } from './client';
import * as GQLTypes from 'gql/graphql';

export type {
  PlayerFragment,
  PlayerShortFragment,
  PlayerUpdateInput,
  SearchPlayersQuery,
} from 'gql/graphql';

export type Player = {
  id: GQLTypes.Player['id'];
  name: GQLTypes.Player['name'];
};

// FRAGMENTS //////////////////////////////////////////////////////////////////

graphql(`
  fragment PlayerPosition on Position {
    id
    wsPosition
    name
    statsbombPosition
  }
`);

graphql(`
  fragment Player on Player {
    age
    birthDate
    birthCountry {
      id
      name
    }
    birthCity {
      id
      name
    }
    startedFootballAt
    foot
    currentTeam {
      id
      logo
      name
    }
    nationalTeamCountry {
      id
      name
    }
    primaryPosition {
      ...PlayerPosition
    }
    secondaryPosition {
      ...PlayerPosition
    }
    height
    id
    mffId
    image
    marketValue
    name
    nameRu
    nameEn
    tmUrl
    passportName
    registrationDate
    clubStatus
    playerContracts {
      id
      startDate
      endDate
      prolongation
      prolongationConditions
      prolongationEndDate
      dealContractor {
        id
        name
        inUse
        contractorType
      }
      currentSalary
      currentSalaryCurrency
      salaryIncreaseOptions
      bonuses
      statusOfSalaryIncreaseOptions
      compensations
      buyoutOption
      playerContractHistories {
        id
        startDate
        endDate
        nextStartDate
        nextEndDate
      }
    }
  }
`);

graphql(`
  fragment PlayerShort on Player {
    id
    name
    passportName
    currentTeam {
      id
      name
    }
  }
`);

// QUERIES ////////////////////////////////////////////////////////////////////

const SEARCH_PLAYERS_QUERY = graphql(`
  query SearchPlayers(
    $query: String
    $club: ID
    $first: Int
    $after: String
    $teamId: ID
    $organizationId: ID
  ) {
    searchPlayers(
      club: $club
      query: $query
      first: $first
      after: $after
      teamId: $teamId
      organizationId: $organizationId
    ) {
      ... on PlayerConnection {
        nodes {
          ...PlayerShort
          passportName
          birthCountry {
            id
            name
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }

      ... on SearchPlayersQueryDomainError {
        code
        message
      }
    }
  }
`);

const PLAYER_QUERY = graphql(`
  query Player($id: ID!) {
    player(id: $id) {
      ...Player

      ... on PlayerQueryDomainError {
        code
        message
      }
    }
  }
`);

// MUTATIONS //////////////////////////////////////////////////////////////////

const CREATE_PLAYER_MUTATION = graphql(`
  mutation CreatePlayer($input: PlayerCreateInput!) {
    player {
      create(input: $input) {
        player {
          ...Player
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_PLAYER_MUTATION = graphql(`
  mutation UpdatePlayer($input: PlayerUpdateInput!) {
    player {
      update(input: $input) {
        player {
          ...Player
        }

        error {
          code
          message
        }
      }
    }
  }
`);

const QUERIES = {
  searchPlayers: async (variables: GQLTypes.SearchPlayersQueryVariables) => {
    const result = await client.query({ query: SEARCH_PLAYERS_QUERY, variables });

    const map: Record<string, GQLTypes.PlayerShortFragment> = {};

    if (result.data.searchPlayers.__typename === 'SearchPlayersQueryDomainError') {
      return Promise.reject(result.data.searchPlayers);
    }

    const { nodes, pageInfo } = result.data.searchPlayers;

    nodes.forEach((record) => {
      map[record.id] = record;
    });

    return {
      nodes: Object.values(map),
      pageInfo,
    };
  },

  getPlayer: async (id: string) => {
    const result = await client.query({
      query: PLAYER_QUERY,
      variables: { id },
    });

    if (result.data.player.__typename === 'PlayerQueryDomainError') {
      return Promise.reject(result.data.player);
    }

    return result.data.player;
  },
};

const MUTATIONS = {
  createPlayer: async (input: GQLTypes.PlayerCreateInput) => {
    const result = await client.mutate({
      mutation: CREATE_PLAYER_MUTATION,
      variables: { input },
    });

    if (result.data.player.create.error) {
      return Promise.reject(result.data.player.create.error);
    }

    return result.data.player.create.player;
  },

  updatePlayer: async (input: GQLTypes.PlayerUpdateInput) => {
    const result = await client.mutate({
      mutation: UPDATE_PLAYER_MUTATION,
      variables: { input },
    });

    if (result.data.player.update.error) {
      return Promise.reject(result.data.player.update.error);
    }

    return result.data.player.update.player;
  },
};

const playerService = {
  ...QUERIES,
  ...MUTATIONS,
};

export default playerService;
