import { useState } from 'react';
import CheckIcon from '@mui/icons-material/DoneRounded';
import OrgChangeIcon from '@mui/icons-material/PeopleOutlined';
import RadioChecked from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioUnchecked from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { Collapse, Stack, useMediaQuery } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import styled from '@emotion/styled/macro';

import { MobileMenuItem } from './MobileMenu';
import useUserStore from 'entities/User.model';
import { CurrentOrgUser } from 'shared/api/user';

const Organization = ({ org }: { org: CurrentOrgUser }) => {
  const { currentOrgUser, setCurrentOrg } = useUserStore();
  const smallScreen = useMediaQuery('(max-width:768px)');

  const active = org.organization.id === currentOrgUser.organization.id;

  const handleOrgChange = () => {
    if (active) return;
    setCurrentOrg(org);
    window.location.reload();
    window.location.href = '/';
  };

  if (smallScreen) {
    return (
      <MobileMenuItem onClick={handleOrgChange}>
        {active ? <RadioChecked /> : <RadioUnchecked />}
        {org.organization.name}
      </MobileMenuItem>
    );
  }

  return (
    <MenuItem onClick={handleOrgChange}>
      {active && (
        <ListItemIcon>
          <CheckIcon fontSize="small" color="primary" />
        </ListItemIcon>
      )}
      <ListItemText inset={!active} primary={org.organization.name} />
    </MenuItem>
  );
};

const Organizations = () => {
  const { record } = useUserStore();
  const { orgUsers } = record;
  const [opened, setOpened] = useState(false);
  const smallScreen = useMediaQuery('(max-width:768px)');

  if (orgUsers.length <= 1) return null;

  const renderOrgs = () =>
    orgUsers.map((orgUser: CurrentOrgUser) => (
      <Organization key={orgUser.organization.id} org={orgUser} />
    ));

  return smallScreen ? (
    <Stack>
      <MobileMenuItem onClick={() => setOpened(!opened)}>
        <OrgChangeIcon /> Сменить организацию
      </MobileMenuItem>
      <Collapse in={opened}>
        <OrganizationsListMobile>{renderOrgs()}</OrganizationsListMobile>
      </Collapse>
    </Stack>
  ) : (
    <OrganizationsList subheader={<ListSubheader>Организации</ListSubheader>}>
      {renderOrgs()}
    </OrganizationsList>
  );
};

export default Organizations;

const OrganizationsList = styled(MenuList)`
  border-bottom: 1px solid var(--neutral-border-light);
`;

const OrganizationsListMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin-top: var(--space-xs);
  padding-left: var(--space-xl);
`;
